<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span>{{ l('班次周期设置') }}</span>
			</div>
		</div>
		<a-row :gutter="8">
			<a-row>
				<a-col :span="4">
					<q-label required :label="l('周期名称')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-input :placeholder="l('周期名称')" v-model="CycleName" />
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('每个周期天数')"> </q-label>
				</a-col>
				<a-col :span="4">
					<a-input-number :min="0" :max="31" @change="cycleChange" v-model="cycle" />
				</a-col>
				<a-col :span="10">
					<span style="font-size: 12px"> 以{{ cycle }}天为周期进行循环，最大周期天数为31天 </span>
				</a-col>
			</a-row>
		</a-row>
		<!--        周期数据-->
		<a-row class="data">
			<a-row v-for="(t, i) in cycle" :key="i">
				<a-col :span="4" style="text-align: right;padding-right: 14px;color: black">
					<i style="color: red">*</i> 第{{ i + 1 }}天:
				</a-col>
				<a-col :span="6">
					<a-select
						show-search
						:placeholder="l('请选择')"
						option-filter-prop="children"
						style="width: 100%"
						@change="selectChange($event, i)"
						:value="selectCode[i]"
					>
						<a-select-option v-for="item in SelectList[i]" :key="item.code">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
		</a-row>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import deepClone from '../../../../shared/utils/deepClone';

export default {
	name: 'cycle-setting',
	mixins: [ModalComponentBase],
	data() {
		return {
			spinning: false,
			//下拉框初始化数据
			ShiftinfoList: [],
			//周期天数
			cycle: 0,
			//选择的值
			selectCode: [],
			//周期名称
			CycleName: undefined,
			//父组件传入的绑定的值
			ruleShiftCodes: undefined,
			//选择框整体数据
			SelectList: [],
		};
	},
	watch: {
		cycle(value) {
			//初始化选择框数据
			for (let i = 0; i < value; i++) {
				let t = deepClone(this.ShiftinfoList);
				t.map((item) => {
					item.code = item.code + '-' + i;
				});
				//判断当前选择的是否存在
				if (!t.find((item) => item.code == this.selectCode[i])) {
					this.selectCode[i] = undefined;
				}
				this.SelectList = [...this.SelectList, [...t]];
			}
		},
	},
	computed: {},
	created() {
		this.fullData();
	},
	mounted() {
		if (this.ShiftinfoList.length > 0) {
			if (!this.ShiftinfoList.find((item) => item.code == 'Rest')) {
				this.ShiftinfoList.push({ code: 'Rest', name: '休息' });
			}
		}
	},
	methods: {
		/**
		 * 周期change
		 * @param number
		 */
		cycleChange(number) {
			if (number > 31) {
				this.cycle = 31;
			} else {
				this.cycle = number;
			}
		},
		/**
		 * 格式化当前选择框数据
		 * @param i
		 * @returns {*[]}
		 */
		mapList(i) {
			let t = deepClone(this.ShiftinfoList);
			t.map((item) => {
				item.code = item.code + '-' + i;
			});
			return [...t];
		},
		/**
		 * 选择框change
		 * @param record
		 * @param 索引
		 */
		selectChange(record, index) {
			this.selectCode[index] = record;
			//更新布局
			this.$forceUpdate();
		},
		save() {
			if (!this.CycleName) {
				return abp.message.warn('请输入周期名称');
			}
			if (this.selectCode.length < this.cycle) {
				return abp.message.warn('排班选择不能有空');
			}
			let str = '';
			let name = '';
			let s = '';
			let list = [];
			let shift;
			for (let i = 0; i < this.cycle; i++) {
				if (!this.selectCode[i]) {
					return abp.message.warn('第' + (i + 1) + '天排班不能为空');
				}
				if (i == 0) {
					s = this.selectCode[i].split('-')[0];
					str = s;
					shift = this.ShiftinfoList.find((item) => item.code == s);
					name = shift.name;
					list = [shift];
				} else {
					s = this.selectCode[i].split('-')[0];
					str += ',' + s;
					shift = this.ShiftinfoList.find((item) => item.code == s);
					name += '-' + shift.name;
					list = [...list, shift];
				}
			}
			this.success({
				success: true,
				CycleName: this.CycleName,
				CycleDays: this.cycle,
				RuleShiftCodes: str,
				RuleShiftName: name,
				ShiftinfoList: list,
			});
		},
	},
};
</script>

<style scoped>
.data {
	max-height: 300px;
	overflow-y: auto;
}
.data::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
	border-radius: 3px;
}
.data::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}
.data::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}
</style>
