<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="spinning">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span>{{ l('排班') }}</span>
				</div>
			</div>
			<a-row :gutter="8">
				<a-row>
					<a-col :span="6">
						<q-label required :label="l('开始日期')"> </q-label>
					</a-col>
					<a-col :span="18">
						<a-date-picker style="width: 100%;" v-model="entity.startTime" @change="startTimeChange" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="6">
						<q-label required :label="l('结束日期')"> </q-label>
					</a-col>
					<a-col :span="18">
						<a-date-picker style="width: 100%;" v-model="entity.endTime" @change="endTimeChange" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="6">
						<q-label required :label="l('开始班次')"> </q-label>
					</a-col>
					<a-col :span="18">
						<a-select
							show-search
							:placeholder="l('请选择')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.attendShiftCode"
							@change="SelectChange"
						>
							<a-select-option v-for="(item, index) in shiftinfoList" :key="index">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="6">
						<q-label required :label="l('法定假日选项')"> </q-label>
					</a-col>
					<a-col :span="18">
						<a-radio-group v-model="entity.ruleCode" :defaultValue="1">
							<a-radio :style="radioStyle" :value="1">
								跳过
							</a-radio>
							<a-row class="tip">
								规则:根据轮班规则，法定这天不排班并且跳过此次班次下一个班次
							</a-row>
							<a-radio :style="radioStyle" :value="2">
								顺延
							</a-radio>
							<a-row class="tip">
								规则:根据轮班规则，法定这天不排班并且此次班次顺延到下一天
							</a-row>
							<a-radio :style="radioStyle" :value="3">
								覆盖
							</a-radio>
							<a-row class="tip">
								规则:根据轮班规则，法定这天也进行排班
							</a-row>
						</a-radio-group>
					</a-col>
				</a-row>
			</a-row>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-row>
					<a-col :span="12" style="text-align: left">
						<a-checkbox @change="isCoverChange" :checked="isCoverEmpPlan">
							覆盖原有排班
						</a-checkbox>
					</a-col>
					<a-col :span="12">
						<a-button @click="close()" type="button">
							<a-icon type="close-circle" />
							{{ l('Cancel') }}
						</a-button>
						<a-button :type="'primary'" @click="save()">
							<a-icon type="save" />
							{{ l('Save') }}
						</a-button>
					</a-col>
				</a-row>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
	AttendanceGroupServiceProxy,
	EnterpriseAttendCycleServiceProxy,
	ShiftEditDto,
} from '../../../../shared/service-proxies';
import moment from 'moment';

export default {
	name: 'shift-model',
	mixins: [ModalComponentBase],
	data() {
		return {
			spinning: false,
			zh_CN,
			shiftinfoList: [],
			radioStyle: {
				display: 'block',
				height: '30px',
				lineHeight: '30px',
			},
			entity: new ShiftEditDto(),
			attGroupId: undefined,
			Year: undefined,
			Moth: undefined,
			isCoverEmpPlan: false,
		};
	},
	created() {
		this.fullData();
		this._attendanceGroupAppService = new AttendanceGroupServiceProxy(this.$apiUrl, this.$api);
		this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		let cycle = await this.getCycle();
		this.entity.startTime = moment(cycle.startTime);
		this.entity.endTime = moment(cycle.endTime);
		if (this.attGroupId) {
			this.entity.attGroupId = this.attGroupId;
			this.getShiftInfo();
			// this.entity.ruleCode=1;
		}
	},
	methods: {
		getShiftInfo() {
			this.spinning = true;
			this._attendanceGroupAppService
				.getShiftinfoByAttGroupId(this.attGroupId)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.shiftinfoList = res;
				});
		},
		isCoverChange(e) {
			this.isCoverEmpPlan = e.target.checked;
		},
		getCycle() {
			return new Promise((resolve, reject) => {
				this.spinning = true;
				this._enterpriseAttendCycleServiceProxy
					.getByTenant(this.Year, this.Month)
					.finally(() => {
						this.spinning = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},
		SelectChange(index, record) {
			this.entity.shiftCodeIndex = index;
		},
		startTimeChange(e) {
			this.entity.startTime = moment(e);
			this.$forceUpdate();
		},
		endTimeChange(e) {
			this.entity.endTime = moment(e);
			this.$forceUpdate();
		},
		save() {
			if (!this.entity.startTime) {
				return abp.message.warn('请选择轮班开始时间');
			}
			if (!this.entity.endTime) {
				return abp.message.warn('请选择轮班结束时间');
			}
			if (this.entity.startTime > this.entity.endTime) {
				return abp.message.warn('开始时间不能大于结束时间');
			}
			if (!this.entity.attendShiftCode && this.entity.attendShiftCode != 0) {
				return abp.message.warn('请选择轮班开始班次');
			}
			if (!this.entity.ruleCode) {
				this.entity.ruleCode = 1;
			}
			if (!this.entity.ruleCode) {
				return abp.message.warn('请选择法定假日选项');
			}
			this.entity.isCoverEmpPlan = this.isCoverEmpPlan;
			this.entity.startTime = moment(this.entity.startTime);
			this.entity.endTime = moment(this.entity.endTime);
			this.spinning = true;
			this._attendanceGroupAppService
				.shift(this.entity)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped lang="less">
/deep/ .ant-radio-group {
	padding-top: 4px;
}
.tip {
	font-size: 10px;
	color: #929292;
}
/deep/ .ant-radio-inner {
	height: 15px;
}
</style>
