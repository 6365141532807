<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="spinning">
            <a-card>
                <div>
                    <a-row>
                        <a-col :span="12">
                            <a-button
                                    :type="'primary'"
                                    @click="create"
                                    v-if="isGranted('attendance_group_add')"
                            >
                                <a-icon type="plus"/>
                                <span>{{ l("Create") }}</span>
                            </a-button>
                            <a-button
                                    :type="'primary'"
                                    @click="edit"
                                    v-if="isGranted('attendance_group_edit')"
                            >
                                <a-icon type="edit"/>
                                <span>{{ l("Edit") }}</span>
                            </a-button>
                            <a-button
                                    :type="'danger'"
                                    @click="Delete"
                                    v-if="isGranted('attendance_group_delete')"
                            >
                                <a-icon type="delete"/>
                                <span>{{ l("Delete") }}</span>
                            </a-button>
                            <a-button
                                    :type="'primary'"
                                    @click="shift()"
                                    v-if="isGranted('attendance_group_shift')"
                            >
                                <span>{{ l("排班") }}</span>
                            </a-button>
                        </a-col>
                        <a-col :span="8" :offset="4">
                                <a-row :gutter="8">
                                    <!-- 搜索 -->
                                    <a-col :sm="24">
                                        <a-form-item>
                                            <a-input-search
                                                    style="width: 200px"
                                                    name="filterText"
                                                    :placeholder="l('SearchWithThreeDot')"
                                                    @search="searchChange"
                                                    enterButton
                                                    v-model="filterText"
                                                    v-decorator="['filterText']"
                                            />
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                        </a-col>
                    </a-row>
                </div>
                <div style="margin-top: -40px">
                    <a-row>
                        <!--                        班组-->
                        <a-col :span="6">
                            <div class="my-md">
                                <a-table
                                        class="list-table"
                                        :pagination="false"
                                        :customRow="customRow"
                                        :columns="columns"
                                        :rowKey="tableDatas => tableDatas.id"
                                        :dataSource="tableData">
                                    <a-checkbox slot="checkbox"
                                                slot-scope="text, record"

                                                :checked="record.checked">
                                    </a-checkbox>
                                </a-table>
                                <a-pagination
                                        class="pagination"
                                        :total="totalItems"
                                        :showTotal="showTotalFun"
                                        v-model="pageNumber"
                                        @change="onChange"/>
                            </div>
                        </a-col>
                        <!--                        日历排期-->
                        <a-col :span="18">
                            <a-calendar :locale="cn" @select="selectCalendar" @panelChange="panelChage"
                                        v-if="tableData.length>0">
                                <ul slot="dateCellRender" slot-scope="value" class="events">
                                    <li v-for="item in getListData(value)" :key="item.content">
                                        <div class="calendar-content">
                                            <a-tag color="#5CB85C" v-if="item.content=='休息'">
                                                <div class="ellipse">
                                                    {{item.content}}
                                                </div>
                                            </a-tag>
                                            <div style="width: 90%" v-else-if="item.content==='法定节假日'">
                                                <a-tag color="pink" class="ellipse">
                                                    {{item.content}}
                                                </a-tag>
                                            </div>
                                            <div style="width: 100%" v-else>
                                                <a-tag color="#2db7f5" class="ellipse">
                                                    {{item.content}}
                                                </a-tag>
                                            </div>
                                        </div>
                                        <div class="calendar-time "
                                             v-if="item.content==='休息' || item.content==='法定节假日'?false:true">
                                            <div class="ellipse">
                                                {{item.startTime}}~{{item.endTime}}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </a-calendar>
                        </a-col>
                    </a-row>
                </div>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../shared/component-base";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import cn from 'moment/locale/zh-cn';
    import moment from 'moment'
    import ModalHelper from '../../../shared/helpers/modal/modal-helper'
    import CreateOrEditAttendanceGroup from './create-or-edit-attendance-group/create-or-edit-attendance-group'
    import ShiftModel from './shift-model/shift-model'
    import EditShiftPlan from './edit-shift-plan/edit-shift-plan'
    import {AttendanceGroupServiceProxy} from "../../../shared/service-proxies";
    import Reg from "../../../shared/utils/reg";

    let _this;
    export default {
        name: "attendance-group",
        mixins: [AppComponentBase],
        components: {CreateOrEditAttendanceGroup, ShiftModel, EditShiftPlan},
        data() {
            return {
                zh_CN,
                cn,
                spinning: false,
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                filterText: undefined,
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40", "50"],
                request: {sorting: "", maxResultCount: 10, skipCount: 0},
                columns: [
                    {
                        title: '',
                        dataIndex: "checkbox",
                        sorter: false,
                        fixed: 'left',
                        width: 50,
                        align: "center",
                        scopedSlots: {customRender: "checkbox"}
                    },
                    {
                        title: this.l("Name"),
                        dataIndex: "name",
                        sorter: false,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "name"}
                    },
                ],
                tableData: [],
                attGroupId: undefined,
                selectedRow: undefined,
                Year: undefined,
                Month: undefined,
                calendarList: [],
            }
        },
        created() {
            _this = this;
            this._attendanceGroupAppService = new AttendanceGroupServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            console.log(Reg.getInstance().RegNumber(10))
            this.getData();
        },
        methods: {
            searchChange(){
                this.request.skipCount=0;
                this.pageNumber=1;
                this.getData();
            },
            getData() {
                this.spinning = true;
                // this.selectedRowKeys = [];
                // this.selectedRows = undefined;
                this._attendanceGroupAppService.getPaged(
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then(res => {
                        this.tableData = res.items;
                        this.totalItems = res.totalCount;
                        this.totalPages = Math.ceil(
                            res.totalCount / this.request.maxResultCount
                        );
                        this.pagerange = [
                            (this.pageNumber - 1) * this.request.maxResultCount + 1,
                            this.pageNumber * this.request.maxResultCount,
                        ];
                        if (this.tableData.length > 0) {
                            this.select(this.tableData[0]);
                            if(!this.Year){
                                this.Year = moment().year();
                            }
                            if(!this.Month){
                                this.Month = moment().month() + 1;
                            }
                            this.calendarInit();
                        }
                        else {
                            this.Year = moment().year();
                            this.Month = moment().month() + 1;
                        }
                        // else if(this.tableData.length > 0&&this.attGroupId){
                        //     console.log(this.attGroupId)
                        //     this.tableData.map(item=>{
                        //         if(item.id==this.attGroupId){
                        //             item.checked=true;
                        //         }
                        //     });
                        //     this.$forceUpdate();
                        // }
                    });
            },
            /**
             * 刷新时的模拟选择
             */
            select(rows) {
                let row = this.tableData.find(item => item.id == rows.id);
                this.attGroupId = rows.id;
                this.selectedRow = rows;
                row.checked = true;
                _this.$forceUpdate();
            },
            /**
             * 获取日历数据
             */
            calendarInit() {
                this.spinning = true;
                this._attendanceGroupAppService.getCalendarList(this.attGroupId, this.Year, this.Month)
                    .finally(() => {
                        this.spinning = false
                    })
                    .then(res => {
                        this.calendarList = res;
                        this.$forceUpdate();
                    })
            },
            /**
             * 表格行点击
             * @param record 点击行的数据
             * @param index 点击行的下标
             */
            customRow: (record, index) => ({

                on: {
                    click: () => {
                        _this.attGroupId = record.id;
                        //点击的是当前用户则不做操作
                        //if(_this.isAdmin(record))return;
                        //设置当前点击的数据
                        _this.selectedRow = record;
                        //设置多选
                        //_this.selectedRowKeys = [record.id];
                        //清空选择
                        _this.tableData.map(item => item.checked = false);
                        //赋值选择
                        record.checked = !record.checked;
                        _this.calendarInit();
                        //强制更新布局
                        _this.$forceUpdate();
                    }
                }
            }),
            /**
             * 分页事件
             */
            showTotalFun() {
                return `共有${this.totalItems}条`;
            },
            /**
             * 分页
             */
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * 日历面板change
             */
            panelChage(record) {
                if (record) {
                    this.Year = moment(record).year();
                    this.Month = moment(record).month() + 1;
                }
                this.calendarInit();
            },
            /**
             * 添加
             */
            create() {
                ModalHelper.create(
                    CreateOrEditAttendanceGroup,
                    {},
                    {
                        width: "900px"
                    }
                ).subscribe(res => {
                    if (res) {
                        this.getData();
                    }
                });
            },
            /**
             * 编辑
             */
            edit() {
                if (!this.attGroupId) {
                    return abp.message.warn('请选择考勤组');
                }
                ModalHelper.create(
                    CreateOrEditAttendanceGroup,
                    {
                        id: this.attGroupId
                    },
                    {
                        width: "900px"
                    }
                ).subscribe(res => {
                    if (res) {
                        this.getData();
                    }
                });
            },
            /**
             * 日历样式数据初始化
             */
            getListData(value) {
                let listData = [];
                let s = this.calendarList.find(item => item.dateTime == moment(value).format('YYYY-MM-DD'));
                if (s) {
                    listData = [s];
                }
                return listData;
            },
            /**
             * 轮班
             */
            shift() {
                if (!this.attGroupId) {
                    return abp.message.warn("请选择一项考勤组进行轮班");
                }
                ModalHelper.create(
                    ShiftModel,
                    {
                        attGroupId: this.attGroupId,
                        Year: this.Year,
                        Month: this.Month
                    },
                    {width: "500px"}
                )
                    .subscribe(res => {
                        if (res) {
                            this.getData();
                            this.calendarInit();
                        }
                    })
            },
            /**
             * 日历点击事件
             * @param date
             */
            selectCalendar(date) {
                if (!this.attGroupId) {
                    return abp.message.warn("请选择考勤组");
                }
                ModalHelper.create(
                    EditShiftPlan,
                    {
                        date: moment(date).format('YYYY-MM-DD'),
                        attGroupId: this.attGroupId
                    },
                    {
                        width: "500px"
                    }
                )
                    .subscribe(res => {
                        if (res) {
                            this.getData();
                            this.calendarInit();
                        }
                    })
            },
            /**
             * 删除
             * @constructor
             */
            Delete() {
                if (!this.selectedRow) {
                    return abp.message.warn("请至少选择一项");
                }
                if (this.selectedRow.userCount > 0) {
                    return abp.message.warn("考勤组内存在人员，暂不能删除");
                }
                this.$confirm({
                    title: "确认信息",
                    content: "确定要删除选中的记录吗？",
                    cancelText: "取消",
                    okText: "确认",
                    onOk: () => {
                        this.spinning = true;
                        this._attendanceGroupAppService.delete(this.selectedRow.id)
                            .finally(() => {
                                this.spinning = false;
                            })
                            .then(res => {
                                this.$notification["success"]({
                                    message: this.l("SuccessfullyDeleted")
                                });
                                this.pageNumber=1;
                                this.request.skipCount=0;
                                this.getData();
                            });
                    }
                });

            }
        }
    }
</script>

<style scoped>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    >>> .ant-fullcalendar-date {
        height: 105px;
    }

    .events {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .events .ant-badge-status {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        font-size: 12px;
    }

    .notes-month {
        text-align: center;
        font-size: 28px;
    }

    .notes-month section {
        font-size: 28px;
    }

    >>> .ant-radio-group {
        display: none;
    }

    .calendar-content {
        width: 100%;
        font-size: 12px;
        padding: 5px 0px;
        text-align: center;
    }

    .calendar-time {
        width: 100%;
        text-align: center;
        font-size: 10px;
    }

    .calendar-text {
        width: 90%;
        padding: 2px 5px;
        font-size: 10px;
        margin: auto;
        color: white;
        border-radius: 2px;
    }

    .bg-bule {
        background-color: #2db7f5;
    }

    .bg-green {
        background-color: #5CB85C;
    }

    .calendar-content, .calendar-time {
        /*display: -webkit-box;*/
        /*-webkit-box-orient: vertical;*/
        /*-webkit-line-clamp: 1;*/
        overflow: hidden;
    }

    .ellipse {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
