<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('EditAttendanceGroup') }}</span>
				<span v-else>{{ l('CreateAttendanceGroup') }}</span>
			</div>
		</div>
		<a-row :gutter="8">
			<a-row>
				<a-col :span="4">
					<q-label required :label="l('Name')"> </q-label>
				</a-col>
				<a-col :span="8">
					<a-input :placeholder="l('Name')" v-model="entity.name" />
				</a-col>
			</a-row>
			<!--            考勤人员-->
			<a-row>
				<a-col :span="4">
					<q-label :label="l('CheckInUser')"> </q-label>
				</a-col>
				<a-col :span="20">
					<div class="CheckInUser" @click="checkInUser">
						<a-tag
							color="#2db7f5"
							v-for="item in CheckInUserList"
							:key="item.userId"
							style="cursor: pointer;"
						>
							{{ item.realName }}
						</a-tag>
					</div>
				</a-col>
			</a-row>
			<!--            无需考勤人员-->
			<a-row>
				<a-col :span="4">
					<q-label :label="l('UnCheckInUser')"> </q-label>
				</a-col>
				<a-col :span="20">
					<div class="CheckInUser" @click="unCheckInUser">
						<a-tag
							color="#2db7f5"
							v-for="item in UnCheckInUserList"
							:key="item.userId"
							style="cursor: pointer;"
						>
							{{ item.realName }}
						</a-tag>

						<!-- <div class="data">
                            共<b>{{ UnCheckInUserList.length }}</b
                            >人
                        </div> -->
						<!-- <div class="data-icon">
                            <a-icon type="right" />
                        </div> -->
					</div>
				</a-col>

				<div style="margin-bottom:10px">
					应出勤时间取考勤组排班!
				</div>

				<label></label>
			</a-row>

			<!--            负责人-->
			<a-row>
				<a-col :span="4">
					<q-label label="考勤负责人"> </q-label>
				</a-col>
				<a-col :span="20">
					<div class="CheckInUser" @click="checkInManage">
						<a-tag
							color="#2db7f5"
							v-for="item in CheckInManageList"
							:key="item.userId"
							style="cursor: pointer;"
						>
							{{ item.realName }}
						</a-tag>
					</div>
				</a-col>
			</a-row>
			<!--            选择班次-->
			<a-row>
				<a-col :span="4">
					<q-label :label="l('CheckInShiftinfo')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-button @click="selectShintinfo" v-if="ShiftInfoList.length <= 0">选择班次</a-button>
					<div class="CheckInUser" @click="selectShintinfo" v-else>
						<a-tag color="#2db7f5" v-for="item in ShiftInfoList" :key="item.id" style="cursor: pointer;">
							{{ item.name }}:{{ item.startTime }}~{{ item.endTime }}
						</a-tag>
					</div>
				</a-col>
			</a-row>
			<!--            排班周期-->
			<a-row>
				<a-col :span="4">
					<q-label :label="l('CheckInCyclee')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-button @click="checkInCyclee" v-if="!entity.cycleDays && !entity.cycleName"
						>请设置排班周期</a-button
					>
					<a-col :span="24" style="border: 1px solid #D9D9D9;border-radius: 5px;" v-else>
						<a-row style="padding-left: 10px;border-bottom: 1px solid #D9D9D9;">
							<a-col :span="6" align="center">
								周期名称
							</a-col>
							<a-col :span="12" align="center">
								周期班次
							</a-col>
							<a-col :span="3" class="text-center">
								周期天数
							</a-col>
							<a-col :span="3" class="text-center">
								操作
							</a-col>
						</a-row>
						<a-row class="data-row" style="border-bottom: none">
							<a-col :span="6" style="line-height: 50px" :title="entity.cycleName">
								<span class="ellipsis" style="width: 95%">
									{{ entity.cycleName }}
								</span>
							</a-col>
							<a-col :span="12" style="line-height: 50px" :title="ruleShiftName">
								<div>
									<span class="ellipsis">
										{{ ruleShiftName }}
									</span>
								</div>
							</a-col>
							<a-col :span="3" class="text-center" style="line-height: 50px">
								{{ entity.cycleDays }}
							</a-col>
							<a-col :span="3" class="text-center" style="line-height: 50px">
								<a @click="checkInCyclee">设置</a>
							</a-col>
						</a-row>
					</a-col>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('AttendanceType')"> </q-label>
				</a-col>
				<a-col :span="8">
					<div style="width: 100%">
						<!--                            <a-checkbox-group :options="options" v-model="AttType" @change="AttTypeChange"/>-->
						<a-checkbox-group @change="AttTypeChange" v-model="AttType">
							<a-row>
								<a-checkbox v-for="(item, key) in options" :value="item.itemDetailCode" :key="key">
									{{ item.itemDetailName }}
								</a-checkbox>
							</a-row>
						</a-checkbox-group>
					</div>
					<div style="font-size: 12px">
						以上方式满足一项，考勤组成员即可完成考勤
					</div>
				</a-col>
				<a-col :span="4" v-if="isCheckInType">
					<q-label :label="l('CheckInType')"> </q-label>
				</a-col>
				<a-col :span="8" v-if="isCheckInType">
					<a-select
						show-search
						:placeholder="l('CheckInType')"
						option-filter-prop="children"
						style="width: 100%"
						@change="checkInTypeChange"
						v-model="CheckInType"
					>
						<a-select-option v-for="item in CheckInTypeList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
			<a-row v-if="AttendanceType">
				<a-col :span="4">
					<q-label :label="l('CheckInAddress')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-row>
						<a-col :span="3">
							<a-button @click="Address">添加</a-button>
						</a-col>
						<a-col :span="16" style="font-size: 12px">
							可以添加多个办公地点
						</a-col>
					</a-row>
				</a-col>
			</a-row>
			<!--            地址卡片-->
			<a-row v-if="AttendanceType" style="padding-bottom: 10px">
				<a-col :span="20" offset="4" style="border: 1px solid #D9D9D9;border-radius: 5px;">
					<a-row style="padding-left: 10px;border-bottom: 1px solid #D9D9D9;">
						<a-col :span="16">
							考勤地址
						</a-col>
						<a-col :span="4" class="text-center">
							有效范围
						</a-col>
						<a-col :span="4" class="text-center">
							操作
						</a-col>
					</a-row>
					<!--                    数据-->
					<a-row class="data-row" v-for="(item, index) in CheckInAddressList" :key="index">
						<a-col :span="16" style="line-height: 31px">
							<div style="color: #333333;height: 20px">
								<span class="ellipsis" :title="item.name">
									{{ item.name }}
								</span>
							</div>
							<div style="font-size: 12px;">
								<span class="ellipsis" :title="item.detailedAddress">
									{{ item.detailedAddress }}
								</span>
							</div>
						</a-col>
						<a-col :span="4" class="text-center" style="line-height: 50px"> {{ item.validLimit }}米 </a-col>
						<a-col :span="4" class="text-center" style="line-height: 50px">
							<a @click="removeAddress(index)">删除</a>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
			<a-row :gutter="8">
				<a-col :span="4">
					<q-label :label="l('Remark')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-textarea placeholder="备注" allow-clear v-model="entity.description" />
				</a-col>
			</a-row>
		</a-row>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import { Dic } from '../../../../shared/utils';
import SelectUserTag from '@/components/select-user-tag/select-user-tag';
import ModalHelper from '../../../../shared/helpers/modal/modal-helper';
import SelectShiftinfoTag from '../select-shiftinfo-tag/select-shiftinfo-tag';
import CycleSetting from '../cycle-setting/cycle-setting';
import MapPositionChoose from '../../mappositionchoose/mappositionchoose';
import {
	AttendanceGroupEditDto,
	AttendanceGroupServiceProxy,
	AttendancePeopleEditDto,
	AttgroupManagerEditDto,
	CheckInAddressEditDto,
	CreateOrUpdateAttendanceGroupInput,
} from '../../../../shared/service-proxies';

export default {
	name: 'create-or-edit-attendance-group',
	mixins: [ModalComponentBase],
	components: { SelectUserTag, SelectShiftinfoTag, CycleSetting, MapPositionChoose },
	data() {
		return {
			spinning: false,
			id: undefined,
			CheckInTypeList: [],
			//考勤人员
			CheckInUserList: [],
			//不参与考勤
			UnCheckInUserList: [],
			//考勤负责人
			CheckInManageList: [],
			entity: new AttendanceGroupEditDto(),
			options: [],
			AttendanceType: false,
			ShiftInfoList: [],
			//打卡定位
			CheckInAddressList: [],
			CheckInType: [],
			AttType: [],
			ruleShiftName: undefined,
			ShiftInfoCycleList: [],
			//删除的班次
			deletedShiftCodeList: [],
			isCheckInType: false,
		};
	},
	created() {
		this.fullData();
		this._attendanceGroupAppService = new AttendanceGroupServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.CheckInTypeList = await Dic.getInstance().getDicAsync('CheckInType');
		this.options = await Dic.getInstance().getDicAsync('CheckType');
		this.options = this.options.filter((item) => item.itemDetailCode != '4' && item.itemDetailCode != '5');
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._attendanceGroupAppService
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.attendanceGroup;
					if (this.entity.checkInType) {
						this.CheckInType = this.entity.checkInType.split(',');
					}
					if (this.entity.attendanceType) {
						this.AttType = this.entity.attendanceType.split(',');
						this.AttTypeChange(this.AttType);
					}
					this.CheckInAddressList = res.checkInAddressList;
					this.UnCheckInUserList = res.unAttendancePeopleList;
					this.CheckInUserList = res.attendancePeopleList;
					this.CheckInManageList = res.attgroupManagerList;
					this.ShiftInfoList = res.shiftInfoList;
					this.ShiftInfoCycleList = res.shiftInfoCycleList;
					if (this.ShiftInfoCycleList.length > 0) {
						let str = '';

						this.ShiftInfoCycleList.forEach((item, index) => {
							if (index == 0) {
								str = item.name;
							} else {
								str += '-' + item.name;
							}
						});
						this.ruleShiftName = str;
					}
				});
		},
		/**
		 * 参与考勤人员
		 */
		checkInUser() {
			let list = [];
			if (this.CheckInUserList.length > 0) {
				list = this.CheckInUserList.map((item) => {
					return { id: item.empId, realName: item.realName, isShow: item.isShow };
				});
			}

			ModalHelper.create(
				SelectUserTag,
				{
					_selectedRows: list,
					_selectedRowKeys: _.map(list, 'id'),
					isMultiple: true,
				},
				{
					width: '900px',
					closable: false,
				}
			).subscribe((res) => {
				if (res) {
					this.CheckInUserList = res.map((item) => {
						let entity = new AttendancePeopleEditDto();
						entity.empId = item.id;
						entity.realName = item.realName;
						entity.isCheckIn = true;
						return entity;
					});
					//判断不考勤人员内是否存在选择的考勤人员，存在就移除
					this.CheckInUserList.forEach((item) => {
						this.UnCheckInUserList = [...this.UnCheckInUserList.filter((t) => t.empId != item.empId)];
					});
				}
			});
		},
		/**
		 * 不参与考勤人员
		 */
		unCheckInUser() {
			let list = [];
			if (this.UnCheckInUserList.length > 0) {
				list = this.UnCheckInUserList.map((item) => {
					return { id: item.empId, realName: item.realName };
				});
			}
			ModalHelper.create(
				SelectUserTag,
				{
					_selectedRows: list,
					_selectedRowKeys: _.map(list, 'id'),
					isMultiple: true,
				},
				{
					width: '900px',
					closable: false,
				}
			).subscribe((res) => {
				if (res) {
					this.UnCheckInUserList = res.map((item) => {
						let entity = new AttendancePeopleEditDto();
						entity.empId = item.id;
						entity.isCheckIn = false;
						entity.realName = item.realName;
						return entity;
					});
					//判断考勤人员内是否存在选择的不考勤人员，存在就移除
					this.UnCheckInUserList.forEach((item) => {
						this.CheckInUserList = [...this.CheckInUserList.filter((t) => t.empId != item.empId)];
					});
				}
			});
		},
		/**
		 * 考勤负责人
		 */
		checkInManage() {
			let list = [];
			if (this.CheckInUserList.length > 0) {
				list = this.CheckInManageList.map((item) => {
					return { id: item.empId, realName: item.realName, isShow: item.isShow };
				});
			}
			ModalHelper.create(
				SelectUserTag,
				{
					_selectedRows: list,
					_selectedRowKeys: _.map(list, 'id'),
					isMultiple: true,
				},
				{
					width: '900px',
					closable: false,
				}
			).subscribe((res) => {
				if (res) {
					this.CheckInManageList = res.map((item) => {
						let entity = new AttgroupManagerEditDto();
						entity.empId = item.id;
						entity.realName = item.realName;
						return entity;
					});
				}
			});
		},
		/**
		 * 选择班次
		 */
		selectShintinfo() {
			let list = [];
			if (this.ShiftInfoList.length > 0) {
				list = this.ShiftInfoList.filter((item) => item.code != 'Rest');
			}
			ModalHelper.create(
				SelectShiftinfoTag,
				{
					_selectedRows: list,
					_selectedRowKeys: _.map(list, 'id'),
					isMultiple: true,
				},
				{ width: '700px' }
			).subscribe((res) => {
				if (res) {
					if (res) {
						//删除的班次
						this.deletedShiftCodeList = [...this.deletedShiftCodeList, ...res.deletedRows];
						//选择的班次
						this.ShiftInfoList = [...res.selectRows];
						let str;
						this.ShiftInfoList.forEach((item, index) => {
							if (index == 0) {
								str = item.code;
							} else {
								str += ',' + item.code;
							}
						});
						this.entity.shiftCodes = str;
					}
				}
			});
		},
		/**
		 * 设置排班周期
		 */
		checkInCyclee() {
			let list = this.ShiftInfoList.filter((item) => item.code != 'Rest');
			if (list.length <= 0) {
				return abp.message.warn('请先设置考勤班次');
			}
			let codes = [];
			if (this.entity.ruleShiftCodes) {
				//格式化成子组件认识的数据
				codes = this.entity.ruleShiftCodes.split(',');
				codes = codes.map((item, index) => {
					return item + '-' + index;
				});
			}
			ModalHelper.create(
				CycleSetting,
				{
					ShiftinfoList: list,
					CycleName: this.entity.cycleName,
					cycle: this.entity.cycleDays,
					selectCode: codes,
				},
				{ width: '700px' }
			).subscribe((res) => {
				if (res.success) {
					this.ShiftInfoCycleList = res.ShiftinfoList;
					this.entity.cycleDays = res.CycleDays;
					this.entity.cycleName = res.CycleName;
					this.entity.ruleShiftCodes = res.RuleShiftCodes;
					this.ruleShiftName = res.RuleShiftName;
					this.$forceUpdate();
				}
			});
		},
		/**
		 * 添加地点
		 * @constructor
		 */
		Address() {
			ModalHelper.create(MapPositionChoose, {}, { width: '900px' }).subscribe((res) => {
				if (res) {
					let entity = new CheckInAddressEditDto();
					entity.name = res.addressName;
					entity.detailedAddress = res.detailedAddress;
					entity.lat = res.lat;
					entity.lng = res.lng;
					entity.validLimit = res.validLimit;
					this.CheckInAddressList.push(entity);
				}
			});
		},
		/**
		 * 删除地址
		 */
		removeAddress(i) {
			this.CheckInAddressList = _.remove(this.CheckInAddressList, (item, index) => {
				return index != i;
			});
		},
		/**
		 * 考勤机打卡方式change
		 */
		checkInTypeChange(record) {
			// let str;
			// record.forEach((item, index) => {
			//     if (index == 0) {
			//         str = item;
			//     } else {
			//         str += "," + item;
			//     }
			// });
			// this.CheckInType=record;
			this.entity.checkInType = record;
		},
		/**
		 * 考勤方式change事件
		 * @param e
		 * @constructor
		 */
		AttTypeChange(e) {
			if (e.find((item) => item == '2')) {
				this.AttendanceType = true;
			} else {
				this.AttendanceType = false;
			}
			if (e.find((item) => item == '1')) {
				this.isCheckInType = true;
				let type = this.CheckInTypeList.find((item) => item.itemDetailName == '指纹/密码/卡/人脸');
				if (type) {
					this.CheckInType = type.itemDetailCode;
				}
			} else {
				this.CheckInType = undefined;
				this.isCheckInType = false;
			}
			let str;
			e.forEach((item, index) => {
				if (index == 0) {
					str = item;
				} else {
					str += ',' + item;
				}
			});
			this.AttType = e;
			this.entity.attendanceType = str;
		},
		save() {
			if (!this.entity.name) {
				return abp.message.warn('请输入名称');
			}
			// if(!this.entity.checkInType){
			//     return  abp.message.warn("请选择考勤机打卡方式")
			// }
			if (this.entity.attendanceType) {
				if (this.entity.attendanceType.indexOf('定位') != -1) {
					if (this.CheckInAddressList.length <= 0) {
						return abp.message.warn('请至少添加一个定位地址');
					}
				}
			}
			//判断当前考勤周期内是不是存在以删除的班次信息
			for (let i = 0; i < this.ShiftInfoCycleList.length; i++) {
				let t = this.deletedShiftCodeList.find((s) => s.code == this.ShiftInfoCycleList[i].code);
				if (t) {
					return abp.message.warn('周期排班第' + (i + 1) + '天排班数据有异常，请重新设置当天排班');
				}
			}
			let input = new CreateOrUpdateAttendanceGroupInput();
			input.attendanceGroup = this.entity;
			input.attgroupManagerList = this.CheckInManageList;
			input.checkInAddressList = this.CheckInAddressList;
			input.attendancePeopleList = [...this.UnCheckInUserList, ...this.CheckInUserList];
			this.spinning = true;
			this._attendanceGroupAppService
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.CheckInUser {
	border: 1px solid #d9d9d9;
	min-height: 35px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
	cursor: pointer;
}

.UserCount {
	width: 150px;
	border: 1px solid #d9d9d9;
	min-height: 35px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: center;
}

.data {
	flex: 4;
	text-align: center;
}

.data-icon {
	flex: 1;
}

.CheckInUser::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}

.CheckInUser::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}

.CheckInUser::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}

.data-row {
	padding-left: 10px;
	width: 98%;
	max-height: 50px;
	border-bottom: 1px solid #d9d9d9;
	margin: auto;
	cursor: pointer;
}

.ellipsis {
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
</style>
