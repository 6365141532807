<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="spinning">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span>{{ l('修改明细') }}</span>
				</div>
			</div>
			<a-row :gutter="8">
				<a-row>
					<a-col :span="4">
						<q-label :label="l('修改日期')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-date-picker disabled style="width: 100%" v-model="date" valueFormat="YYYY-MM-DD" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('原始班别')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-input :placeholder="l('原始班别')" disabled v-model="name" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('修改班次')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-select
							show-search
							:placeholder="l('请选择')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="code"
						>
							<a-select-option v-for="item in shiftinfoList" :key="item.code">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
			</a-row>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-row>
					<a-col :span="12" style="text-align: left">
						<a-checkbox @change="isCoverChange" :checked="isCoverEmpPlan">
							覆盖考勤组所有人员班次
						</a-checkbox>
					</a-col>
					<a-col :span="12">
						<a-button @click="close()" type="button">
							<a-icon type="close-circle" />
							{{ l('Cancel') }}
						</a-button>
						<a-button :type="'primary'" @click="save()">
							<a-icon type="save" />
							{{ l('Save') }}
						</a-button>
					</a-col>
				</a-row>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { AttendanceGroupServiceProxy } from '../../../../shared/service-proxies';
import moment from 'moment';

export default {
	name: 'edit-shift-plan',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			spinning: false,
			shiftinfoList: [],
			date: undefined,
			attGroupId: undefined,
			name: undefined,
			code: undefined,
			isCoverEmpPlan: false,
		};
	},
	created() {
		this.fullData();
		this._attendanceGroupAppService = new AttendanceGroupServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		if (this.date && this.attGroupId) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._attendanceGroupAppService
				.getAttGroupDetailedEdit(this.attGroupId, moment(this.date))
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.shiftinfoList = res.shiftInfoList;
					this.name = res.shiftInfoName;
				});
		},
		isCoverChange(e) {
			this.isCoverEmpPlan = e.target.checked;
		},
		save() {
			if (!this.code) {
				return abp.message.warn('请选择修改班次');
			}
			this.spinning = true;
			this._attendanceGroupAppService
				.updateAttGroupPlanByDateTime(this.attGroupId, moment(this.date), this.code, this.isCoverEmpPlan)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped lang="less">
/deep/ .ant-calendar-picker-input,
/deep/ .ant-input {
	background-color: white;
	color: #333333;
	cursor: auto;
}
</style>
