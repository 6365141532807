

class Reg{
    private  static instance :Reg=null;
    constructor(){}

    static getInstance(): Reg{
        if(this.instance == null){
            this.instance = new Reg();
        }
        return this.instance;
    }
    /**
     * 验证邮箱是否正确
     * @param value
     * @constructor
     */
    RegEmail(value){
        let r=/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if(!value){
            return  false;
        }
        if(r.test(value)){
            return true;
        }
        else {
            return  false;
        }
    }

    /**
     * 验证手机号(11位，第二位123456789,全为数字)
     * @param value
     * @constructor
     */
    RegMobile(value){
        let r=/^1[123456789]\d{9}$/;
        if(!value){
            return false;
        }
        if(r.test(value)){
            return true;
        }
        else {
            return  false;
        }

    }

    /**
     * 验证是否为数字
     * @param value
     * @constructor
     */
    RegNumber(value){
        let r=/^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;
        if(!value){
            return false;
        }
        if(r.test(value)){
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * 验证中文
     * @param value
     * @constructor
     */
    RegChinese(value){
        let r= /^[u4E00-u9FA5]+$/;
        if(!value){
            return false;
        }
        if(r.test(value)){
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * 验证英文
     * @param value
     * @constructor
     */
    RegEnglish(value){
        let r=/^[a-z]*|[A-Z]*$/;
        if(!value){
            return false;
        }
        if(r.test(value)){
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * 验证身份证号
     * @param value
     * @constructor
     */
    RegCard(value){
        let r=/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if(!value){
            return false;
        }
        if(r.test(value)){
            return  true;
        }
        else {
            return false;
        }
    }
}
export default Reg;
