<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span>{{ l('CreateAttendanceAddress') }}</span>
			</div>
		</div>
		<a-config-provider :locale="zh_CN">
			<a-row :gutter="8" class="box">
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('AddressName')"></q-label>
					</a-col>
					<a-col :span="8">
						<a-input :placeholder="l('AddressName')" v-model="addressName" />
						<br /><label style="color: #bfbfbf; font-size: 12px">经度：</label
						><label style="color: #bfbfbf; font-size: 12px">{{ lng }}</label>
						<label style="color: #bfbfbf; font-size: 12px; margin-left: 16px">维度：</label
						><label style="color: #bfbfbf; font-size: 12px">{{ lat }}</label> <br /><label
							style="color: #bfbfbf; font-size: 12px"
							>详细地址：</label
						><label style="color: #bfbfbf; font-size: 12px">{{ detailedAddress }}</label>
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('ValidLimit')"></q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							style="width: 60%"
							:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
							v-model="validLimit"
						/>
						&nbsp;&nbsp;米
					</a-col>
				</a-row>
				<a-row></a-row>
				<a-row>
					<a-input-search
						id="keyword"
						style="margin-bottom: 8px; width: 38%"
						enterButton
						value="请输入关键字：(选定后搜索)"
						onfocus='this.value=""'
					/>
					<div :style="{ width: '100%', height: '500px' }" id="mapContainer"></div>
				</a-row>
			</a-row>
		</a-config-provider>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="sureMapChoose()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>
<style>
.amap-demo {
	height: 300px;
}
</style>
<script>
import { ModalComponentBase } from '../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { Dic } from '../../../shared/utils';
import moment from 'moment';
import Map from '../../../../libs/abp/abp';
import httpClient from '../../../shared/utils/http-client';

export default {
	name: 'mappositionchoose',
	mixins: [ModalComponentBase],
	data() {
		const self = this;
		return {
			spinning: false,
			id: undefined,
			zh_CN,
			cityLocation: '',
			lnglat: [120.677746, 31.240637],
			zoom: 15,
			selectAddress: '',
			selectLngLat: '',
			addressName: '', //地址名称
			detailedAddress: '', //详细地址
			lat: 0, //维度
			lng: 0, //经度
			validLimit: 0, //有效范围
		};
	},
	created() {
		this.fullData();
	},
	async mounted() {
		this.searchAddressMethod();
	},
	methods: {
		searchAddressMethod() {
			const self = this;

			AMap.plugin(
				[
					'AMap.CitySearch',
					'AMap.Autocomplete',
					'AMap.PlaceSearch',
					'AMap.Geocoder',
					'AMap.ToolBar',
					'AMap.Geolocation',
				],
				function() {
					var citySearch = new AMap.CitySearch();
					var map;
					// 搜索插件
					var placeSearch;

					// 城市定位 begin
					citySearch.getLocalCity(function(status, result) {
						if (status === 'complete' && result.info === 'OK') {
							// 查询成功，result即为当前所在城市信息
							let rectangle = result.rectangle.split(';')[1].split(',');
							self.lnglat = rectangle.map(Number);

							// 初始化地图 begin
							map = new AMap.Map('mapContainer', {
								resizeEnable: true,
								// center: self.lnglat,//地图中心点
								zoom: self.zoom, //地图显示的缩放级别
								keyboardEnable: false,
							});
							map.addControl(new AMap.ToolBar());
							// 初始化地图 end

							// 绑定点击事件 begin
							let marker, geocoder;
							AMap.event.addListener(map, 'click', function(e) {
								// 创建标记 begin
								if (marker != null) {
									marker.setMap(null);
								}
								marker = new AMap.Marker({
									icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
									position: e.lnglat,
									offset: new AMap.Pixel(-13, -30),
								});
								marker.setMap(map);
								// 创建标记 end

								// 解析位置 begin
								// 获取经纬度
								self.selectLngLat = e.lnglat.lat + '，' + e.lnglat.lng;
								self.lng = e.lnglat.lng;
								self.lat = e.lnglat.lat;
								if (!geocoder) {
									geocoder = new AMap.Geocoder({
										// city: "010", //城市设为北京，默认：“全国”
										radius: 1000, //范围，默认：500
									});
								}

								geocoder.getAddress(e.lnglat, function(status, result) {
									if (status === 'complete' && result.regeocode) {
										var address = result.regeocode.formattedAddress;
										self.selectAddress = address;
										self.detailedAddress = address;
										self.addressName = address;
									} else {
										log.error('根据经纬度查询地址失败');
									}
								});
								// 解析位置 end
							});
							// 绑定点击事件 end
						}
					});
					// 城市定位 end

					// 搜索联想提示 begin
					var autoOptions = {
						input: 'keyword', //使用联想输入的input的id
					};
					var autocomplete = new AMap.Autocomplete(autoOptions);
					AMap.event.addListener(autocomplete, 'select', function(e) {
						//TODO 针对选中的poi实现自己的功能
						self.addressName = e.poi.name;
						self.detailedAddress = e.poi.address;
						if (e.poi.location) {
							self.lng = e.poi.location.lng;
							self.lat = e.poi.location.lat;
						}
						placeSearch = new AMap.PlaceSearch({
							map: map,
						});
						placeSearch.search(e.poi.name);
					});
					// 搜索联想提示 end
				}
			);
		},

		sureMapChoose(e) {
			var that = this;
			if (that.addressName == '') {
				abp.message.warn(this.l('CanNotNull', this.l('AddressName')));
			} else if (that.validLimit <= 0) {
				abp.message.warn(this.l('CanNotNull', this.l('ValidLimit')));
			} else if (that.detailedAddress == '') {
				abp.message.warn(this.l('CanNotNull', this.l('LngLat')));
			} else {
				this.success({
					addressName: that.addressName,
					detailedAddress: that.detailedAddress,
					lng: that.lng,
					lat: that.lat,
					validLimit: that.validLimit,
				});
			}
		},
	},
};
</script>

<style scoped>
#mapContainer {
	width: 70vw;
	height: 50vh;
}

#mapContainer img {
	width: 20px;
}
</style>
