<template>
	<a-spin :spinning="loading">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span>{{ l('选择班次') }}</span>
			</div>
		</div>
		<!-- usePagination开启分页 -->
		<!-- loading绑定引入页面的蒙层加载变量 -->
		<q-container usePagination :loading="loading">
			<!-- 使用具名插槽 #toolbar -->
			<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
			<template #toolbar>
				<div class="my-md">
					<p v-if="mergeRows.length <= 0" class="NotR">
						已选班次
					</p>
					<a-tag closable @close="tagcol(item, item.id)" v-for="item in mergeRows" :key="item.id" v-else>
						{{ item.name }}
					</a-tag>
				</div>
			</template>
			<!-- 使用具名插槽slot="table" -->
			<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
			<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

			<!-- **************行选择*************** -->
			<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
			<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
			<template slot="table" slot-scope="scopeParam">
				<a-table
					ref="table"
					size="small"
					:pagination="false"
					:columns="columns"
					:rowKey="(tableDatas) => tableDatas.id"
					:scroll="{ y: scopeParam.scroll.y }"
					:dataSource="tableData"
					:customRow="customRow"
					:rowClassName="rowClassName"
				>
					<span slot="shiftTime" slot-scope="text, record">
						{{ record.startTime }}-{{ record.endTime }}
					</span>
				</a-table>
			</template>
			<!-- 使用具名插槽 #page -->
			<template #page>
				<!-- 分页组件只需复制以下代码 -->
				<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
				<a-pagination
					v-model="pageNumber"
					:total="totalItems"
					showSizeChanger
					size="small"
					:defaultPageSize="request.maxResultCount"
					:pageSizeOptions="pageSizeOptions"
					:showTotal="() => showTotal"
					@change="onPageChange"
					@showSizeChange="showPageSizeChange"
				/>
			</template>
		</q-container>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import * as _ from 'lodash';
import { ShiftInfoServiceProxy } from '../../../../shared/service-proxies';

export default {
	name: 'select-shiftinfo-tag',
	mixins: [ModalComponentBase],
	data() {
		return {
			columns: [
				{
					title: this.l('Name'),
					dataIndex: 'name',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'name' },
				},
				{
					title: this.l('AttendanceTime'),
					dataIndex: 'shiftTime',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'shiftTime' },
				},
			],
			tableData: [],
			_selectedRows: [], //源数据
			_selectedRowKeys: [],
			deletedRows: [],
		};
	},
	computed: {
		mergeRows() {
			//根据ID得并集
			return _.unionBy(this._selectedRows, this.selectedRows, 'id');
		},
	},
	created() {
		this.fullData();
	},
	mounted() {
		this._shiftInfoServiceProxy = new ShiftInfoServiceProxy(this.$apiUrl, this.$api);
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this._shiftInfoServiceProxy
				.getPaged(this.filterText, this.request.sorting, this.request.maxResultCount, this.request.skipCount)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
					//轮循key
					for (let key of this._selectedRowKeys) {
						//从本页数据中查询key
						const index = this.tableData.findIndex((d) => d.id == key);
						//如果存在
						if (index != -1) {
							//从已选中的数据中找到与本页一样ID的数据
							const keyIndex = this._selectedRows.findIndex((r) => r.id == this.tableData[index].id);
							//删除已选中的数据
							this._selectedRows.splice(keyIndex, 1);
							//重新选中
							this.rowSelect(this.tableData[index], index);
						}
					}
				});
		},
		//点击标签删除
		tagcol(record, id) {
			//从本页数据中找删除的这条数据
			const index = this.tableData.findIndex((t) => t.id == id);
			//删除本页数据
			if (index != -1) {
				//取消行选中 == 删除
				this.rowSelect(record, index);
			} //删除其他页数据
			else {
				//从已选中的keys中找到
				const keyIndex = this._selectedRowKeys.findIndex((r) => r == record.id);
				if (keyIndex != -1) {
					//删除key
					this._selectedRowKeys.splice(keyIndex, 1);
				}
				//从已选中的数据中找到
				const rowIndex = this._selectedRows.findIndex((r) => r.id == record.id);
				if (rowIndex != -1) {
					//删除数据
					this._selectedRows.splice(rowIndex, 1);
				}
			}
			//保存删除的数据
			let r = this.deletedRows.find((t) => t.id == id);
			if (!r) {
				this.deletedRows = [...this.deletedRows, record];
			}
		},
		save() {
			this.success({
				//重新获取并集
				selectRows: _.unionBy(this._selectedRows, this.selectedRows, 'id'),
				deletedRows: this.deletedRows,
			});
		},
		//重写混入方法
		onPageChange(page) {
			this.pageNumber = page;
			this.request.skipCount = (page - 1) * this.request.maxResultCount;
			this.getData();
		},
		showPageSizeChange(current, size) {
			this.pageNumber = 1;
			this.request.maxResultCount = size;
			this.getData();
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
.my-md {
	border: 1px solid #d9d9d9;
	min-height: 45px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
}
.NotR {
	height: 20px;
	color: #bfbfbf;
}
.my-md::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}
.my-md::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}
.my-md::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}
</style>
